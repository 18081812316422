import {DatePipe} from '@angular/common';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Company} from 'src/app/_models/company';
import {ItemOrder} from 'src/app/_models/itemOrder';
import {OrderedItem} from 'src/app/_models/orderedItem';
import {AlertifyService} from 'src/app/_services/alertify.service';
import {AuthService} from 'src/app/_services/auth.service';
import {CompanyDialService} from 'src/app/_services/companyDial.service';

@Component({
  selector: 'app-issueCreate-modal',
  templateUrl: './issueCreate-modal.component.html',
  styleUrls: ['./issueCreate-modal.component.scss']
})
export class IssueCreateModalComponent implements OnInit {
  @Output() createItemOrder = new EventEmitter();
  itemOrder: ItemOrder;
  itemOrderAddForm: FormGroup;
  companies: Company[];
  companiesClient: Company[];
  itemsValid: boolean = true;

  companyId: string;
  orderedItems: OrderedItem[] = [];

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private companyService: CompanyDialService,
              private authService: AuthService,
              private alertify: AlertifyService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1, 1).subscribe(data => {
      this.companies = data.result;
      if (this.companyId !== '0') {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId.toString());
      } else {
        this.companies = data.result;
      }
      this.companiesClient = this.companies.filter(x => x.client == true || x.isPrimary == true);
      this.companies = this.companies.filter(x => x.supplier == true);
      // this.addEmptyInvoiceItem();
    });
  }


  // tslint:disable-next-line: typedef
  createForm() {
    this.itemOrderAddForm = this.fb.group({
      dateOfOrder: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],

      createdById: [this.authService.decodedToken.nameid, Validators.required],
      supplierId: [0],
      customerId: ['', Validators.required],
      orderState: ['new issue'],
      deleted: [false],

    });
    if (this.authService.decodedToken.IsPrimary !== 'True') {
      this.itemOrderAddForm.patchValue({supplierId: this.companies.filter(x => x.isPrimary)[0].id})
    }

  }

  createOrder() {
    if (this.itemOrderAddForm.valid) {

      this.itemOrder = Object.assign({}, this.itemOrderAddForm.value);
      this.itemOrder.orderedItems = this.orderedItems;
      this.createItemOrder.emit(this.itemOrder);
      this.bsModalRef.hide();
    }
  }

  addEmptyInvoiceItem() {
    const emptyInvItem = {} as OrderedItem;
    emptyInvItem.amount = 0;
    emptyInvItem.name = '';
    emptyInvItem.price = 0;
    emptyInvItem.itemOrderId = 0;
    emptyInvItem.deleted = false;
    emptyInvItem.dynamicId = this.orderedItems.reduce((op, item) => op = op > item.dynamicId ? op : item.dynamicId, 0) + 1;
    this.orderedItems.push(emptyInvItem);
    this.checkInvItems();
  }

  removeInvoiceItem(id: number) {
    this.orderedItems = this.orderedItems.filter(x => x.dynamicId != id);
    this.checkInvItems();
  }

  checkInvItems() {
    if (this.orderedItems.length <= 0) {
      this.itemsValid = true;
      return;
    }

    var error = this.orderedItems.filter(x =>
        x.amount === 0 || x.amount === null ||
        x.price === undefined || x.price === null ||
        x.name === undefined || x.name === '' || x.name === null
      //  x.flag === undefined || x.flag ===''
    ).length > 0;

    this.itemsValid = !error;
  }


}
