import { FileReportItem } from './fileReportItem';
import { Mailbox } from './mailbox';
import { PCReport } from './pcReport';
import { SqlItem } from './sqlItem';

export class MonthlyReport{
    companyName: string;
    month: number;
    year: number;
    pcReport: PCReport;
    mailboxes: DomainsReportEntity[];
    sqlItems: DomainsReportEntity[];
    fileReportItems: DomainsReportEntity[];
}

export class DomainsReportEntity{
    domain: string;
    fileReportItems: FileReportItem[];
    mailboxes: Mailbox[];
    sqlInstances: SqlItem[];
}
