<div class="m-5">
  <div *ngIf="filterParams.companyId !== '1'">
  <h2 >Základní ceník</h2>
  </div>
  <h2 class="m-0 p-0" *ngIf="filterParams.companyId ==='1'">Základní ceník</h2>

  <button class="btn btn-primary " (click)="createModal()">Přidat</button>
  <div *ngIf="basePriceList !== undefined" class="m-3 ">
    <div *ngIf=" basePriceList.length === 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné položky</h1>
    <button class="btn btn-primary m-3" (click)="createModal()">Vytvořit novou položku</button>
    </div>

  <div class="row w-100" *ngIf="basePriceList != undefined && basePriceList.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <tr>
        <th style="width: 7%">Kód služby</th>
        <th style="width: 15%">Název</th>
        <th style="width: 5%">Cena za mj</th>
        <th style="width: 5%">Cena za mj (s DPH)</th>
        <th style="width: 5%">Měsíčně</th>
        <th style="width: 15%">Jednotka slovně</th>
        <th style="width: 20%">Definice služby</th>
        <th style="width: 8%"></th>
      </tr>
      <tr *ngFor="let basePriceListItem of basePriceList" >
        <td>{{basePriceListItem.code}}</td>
        <td>{{basePriceListItem.name}}</td>
        <td>{{basePriceListItem.price}}</td>
        <td>{{basePriceListItem.price*1.21}}</td>
        <td>
          <ng-container *ngIf="basePriceListItem.isMonthly"><i class="fa fa-check-circle-o" style="color:grey"></i></ng-container>
        </td>
        <td>{{basePriceListItem.unitDefinition}}</td>
        <td>
          <div  class="form-group">
            <textarea readonly  type="text"  class="form-control " [value]= basePriceListItem.serviceDescription
             style="min-height: 40px;" ></textarea>
          </div>
        </td>

        <td>
          <div>
            <button class="btn btn-primary mr-2" (click)="$event.stopPropagation()" (click)="getEditModal(basePriceListItem)" ><i class="fa fa-edit ml-1"></i></button>
            <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deletePriceList(basePriceListItem)" ><i class="fa fa-trash"></i></button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true"
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>
</div>
