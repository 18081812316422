import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { BasePriceListItem } from '../_models/basePriceListItem';

@Injectable({
  providedIn: 'root'
})
export class BasePriceListService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addBasePriceListItem(basepricelistitem: BasePriceListItem)
  {
    return this.http.post<number>(this.baseUrl + 'BasePriceList/', basepricelistitem );
  }

  deleteBasePriceListItem(basepricelistitemId: number)
  {
    return this.http.delete(this.baseUrl + 'BasePriceList/' + basepricelistitemId );
  }

  getBasePriceListItems( page?, itemsPerPage?)
  {
    const paginatedResult: PaginatedResult<BasePriceListItem[]> = new PaginatedResult<BasePriceListItem[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    return this.http.get<BasePriceListItem[]>(this.baseUrl + 'BasePriceList', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  getBasePriceListItemsForOrderList(userParams?)
  {
    let params = new HttpParams();
    if ( userParams != null)
    {
      params = params.append('Month', userParams.month);
      params = params.append('CompanyId', userParams.companyId);
      params = params.append('FilterInvoiced', userParams.filterInvoiced);
      params = params.append('DeductHours', userParams.deductHours);
      params = params.append('GetHours', userParams.getHours);
      
    }
    return this.http.get<BasePriceListItem[]>(this.baseUrl + 'BasePriceList/GetBasePriceListForOrderList', {observe: 'response', params});
  }

  getBasePriceListItemsByInvoiceId(invoiceId)
  {
    return this.http.get<BasePriceListItem[]>(this.baseUrl + 'BasePriceList/GetBasePriceListByInvoiceId/'+invoiceId);
  }
  
  updateBasePriceListItem(id: number, basepricelistitem: BasePriceListItem)
  {
    return this.http.put<number>(this.baseUrl + 'BasePriceList/' + id, basepricelistitem);
  }


  importDefaultPriceList(companyid: number)
  {
    return this.http.post(this.baseUrl + 'BasePriceList/ImportDefaultBasePriceList/' + companyid,{});
  }
}
