import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { FileUploadModule } from 'ng2-file-upload';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AuthService } from './_services/auth.service';
import { HomeComponent } from './home/home.component';
import { ErrorInterceptor, ErrorInterceptorProvider } from './_services/error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { approutes } from './routes';
import { MemberDetailResolver } from './_resolvers/member-detail.resolver';
import { MemberListResolver } from './_resolvers/member-list.resolver';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { TimeagoModule } from 'ngx-timeago';
import { ListsResolver } from './_resolvers/lists.resolver';
import { MessagesResolver } from './_resolvers/messages.resolver';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { HasRoleDirective } from './_directives/hasRole.directive';
import { UserManagementComponent } from './admin/admin-panel/user-management/user-management.component';
import { AdminService } from './_services/admin.service';
import { RolesModalComponent } from './admin/admin-panel/roles-modal/roles-modal.component';
import { CompaniesResolver } from './_resolvers/companies.resolver';
import { CompanyComponent } from './company/company.component';
import { CompanycreateModalComponent } from './company/companycreate-modal/companycreate-modal.component';
import { CompanydetailModalComponent } from './company/companydetail-modal/companydetail-modal.component';
import { CompanyeditModalComponent } from './company/companyedit-modal/companyedit-modal.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketsResolver } from './_resolvers/tickets.resolver';
import { MyTicketsComponent } from './tickets/my-tickets/my-tickets.component';
import { TicketaddModalComponent } from './tickets/ticketadd-modal/ticketadd-modal.component';
import { TicketMessagesComponent } from './tickets/ticket-messages/ticket-messages.component';
import { TicketResolver } from './_resolvers/ticket.resolver';
import { UserWithRolesResolver } from './_resolvers/userWithRoles.resolver';
import { TicketSolverEditModalComponent } from './tickets/ticketSolverEdit-modal/ticketSolverEdit-modal.component';
import { PriorityComponent } from './priority/priority.component';
import { PrioritiesResolver } from './_resolvers/priorities.resolver';
import { PriorityCreateModalComponent } from './priority/prioritycreate-modal/prioritycreate-modal.component';
import { PriorityEditModalComponent } from './priority/priorityedit-modal/priorityedit-modal.component';
import { DatePipe } from '@angular/common';
import { CategoriesResolver } from './_resolvers/categories.resolver';
import { CategoryComponent } from './category/category.component';
import { CategoryEditModalComponent } from './category/categoryedit-modal/categoryedit-modal.component';
import { CategoryCreateModalComponent } from './category/categorycreate-modal/categorycreate-modal.component';
import { WorkReportResolver } from './_resolvers/workreport.resolver';
import { WorkReportsComponent } from './workreports/workreports.component';
import { WorkReportCreateModalComponent } from './workreports/workreportcreate-modal/workreportcreate-modal.component';
import { WorkReportEditModalComponent } from './workreports/workreportedit-modal/workreportedit-modal.component';
import { AdminHomeComponent } from './home/adminHome/adminHome.component';
import { AdminHomeResolver } from './_resolvers/adminHome.resolver';
import { WorkReportDetailModalComponent } from './workreports/workreportdetail-modal/workreportdetail-modal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BasePriceListComponent } from './basepricelist/basepricelist.component';
import { BasePriceListItemCreateModalComponent } from './basepricelist/basepriceListItemCreate-modal/basepriceListItemCreate-modal.component';
import { BasePriceListItemEditModalComponent } from './basepricelist/basepriceListItemEdit-modal/basepriceListItemEdit-modal.component';
import { BasePriceListResolver } from './_resolvers/basePriceList.resolver';
import { PriceListComponent } from './pricelist/pricelist.component';
import { PriceListItemCreateModalComponent } from './pricelist/priceListItemCreate-modal/priceListItemCreate-modal.component';
import { PriceListItemEditModalComponent } from './pricelist/priceListItemEdit-modal/priceListItemEdit-modal.component';
import { PriceListItemsResolver } from './_resolvers/priceListItems.resolver';
import { OrdersResolver } from './_resolvers/orders.resolver';
import { OrdersComponent } from './orders/orders.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceEditModalComponent } from './invoice/invoiceedit-modal/invoiceedit-modal.component';
import { InvoiceCreateModalComponent } from './invoice/invoicecreate-modal/invoicecreate-modal.component';
import { InvoicesResolver } from './_resolvers/invoices.resolver';
import { InvoiceDetailModalComponent } from './invoice/invoicedetail-modal/invoicedetail-modal.component';
import { UserEditComponent } from './user/userEdit/userEdit.component';
import { userEditResolver } from './_resolvers/userEdit.resolver';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { InvoiceWpsModalComponent } from './invoice/invoiceWps-modal/invoiceWps-modal.component';
import { RolegroupsComponent } from './rolegroups/rolegroups.component';
import { GroupsResolver } from './_resolvers/groups.resolver';
import { AddGroupComponent } from './rolegroups/addGroup/addGroup.component';
import { EditGroupComponent } from './rolegroups/editGroup/editGroup.component';
import { InventoryItemsComponent } from './inventoryItems/inventoryItems.component';
import { InventoryItemsResolver } from './_resolvers/InventoryItems.resolver';
import { InventoryItemCreateModalComponent } from './inventoryItems/inventoryItemCreate-modal/inventoryItemCreate-modal.component';
import { InventoryItemEditModalComponent } from './inventoryItems/inventoryItemEdit-modal/inventoryItemEdit-modal.component';
import { InventoryItemDetailModalComponent } from './inventoryItems/inventoryItemDetail-modal/inventoryItemDetail-modal.component';
import { DomainComponent } from './domain/domain.component';
import { DomainResolver } from './_resolvers/domains.resolver';
import { DomainCreateModalComponent } from './domain/domainCreate-modal/domainCreate-modal.component';
import { DomainEditModalComponent } from './domain/domainEdit-modal/domainEdit-modal.component';
import { DomainDetailModalComponent } from './domain/domainDetail-modal/domainDetail-modal.component';
import { TicketsForRepeatComponent } from './ticketsForRepeat/ticketsForRepeat.component';
import { TicketEditModalComponent } from './ticketsForRepeat/ticketEdit-modal/ticketEdit-modal.component';
import { RegisterUserModalComponent } from './admin/admin-panel/registerUser-modal/registerUser-modal.component';
import { PsswdChangeModalComponent } from './admin/admin-panel/psswdChange-modal/psswdChange-modal.component';
import { UserDataEditModalComponent } from './admin/admin-panel/userDataEdit-modal/userDataEdit-modal.component';
import { UserDataDetailModalComponent } from './admin/admin-panel/userDataDetail-modal/userDataDetail-modal.component';
import { UploadComponentComponent } from './uploadComponent/uploadComponent.component';
import { NotificationsSetModalComponent } from './admin/admin-panel/notificationsSet-modal/notificationsSet-modal.component';
import { TicketDataEditModalComponent } from './tickets/ticketDataEdit-modal/ticketDataEdit-modal.component';
import { WorkPostComponent } from './workPost/workPost.component';
import { WorkPostResolver } from './_resolvers/workPosts.resolver';
import { WorkPostCreateModalComponent } from './workPost/workPostCreate-modal/workPostCreate-modal.component';
import { WorkPostEditModalComponent } from './workPost/workPostEdit-modal/workPostEdit-modal.component';
import { TicketDetailModalComponent } from './tickets/ticket-detail-modal/ticket-detail-modal.component';
import { ItemOrdersComponent } from './itemOrders/itemOrders.component';
import { ItemOrdersResolver } from './_resolvers/ItemOrders.resolver';
import { ItemOrderDetailModalComponent } from './itemOrders/itemOrderDetail-modal/itemOrderDetail-modal.component';
import { ItemOrderCreateModalComponent } from './itemOrders/itemOrderCreate-modal/itemOrderCreate-modal.component';
import { ItemOrderEditModalComponent } from './itemOrders/itemOrderEdit-modal/itemOrderEdit-modal.component';
import { IssuesComponent } from './Issues/Issues.component';
import { IssueCreateModalComponent } from './Issues/issueCreate-modal/issueCreate-modal.component';
import { IssueEditModalComponent } from './Issues/issueEdit-modal/issueEdit-modal.component';
import { IssueDetailModalComponent } from './Issues/issueDetail-modal/issueDetail-modal.component';
import { ReminderComponent } from './reminder/reminder.component';
import { ReminderCreateModalComponent } from './reminder/reminderCreate-modal/reminderCreate-modal.component';
import { ReminderEditModalComponent } from './reminder/reminderEdit-modal/reminderEdit-modal.component';
import { RemindersResolver } from './_resolvers/reminders.resolver';
import { SendReminderModalComponent } from './Issues/sendReminder-modal/sendReminder-modal.component';
import { IssuesOrderOverviewComponent } from './Issues/IssuesOrderOverview/IssuesOrderOverview.component';
import { IssuesDeliveryListOverviewComponent } from './Issues/IssuesDeliveryListOverview/IssuesDeliveryListOverview.component';
import { IssueInvoiceOverviewComponent } from './Issues/issueInvoiceOverview/issueInvoiceOverview.component';
import { ItemOrderOverviewComponent } from './itemOrders/itemOrderOverview/itemOrderOverview.component';
import { ItemOrderReceiptOverviewComponent } from './itemOrders/itemOrderReceiptOverview/itemOrderReceiptOverview.component';
import { ItemOrderInvoiceOverviewComponent } from './itemOrders/itemOrderInvoiceOverview/itemOrderInvoiceOverview.component';
import { LicenceComponent } from './licence/licence.component';
import { LicencecreateModalComponent } from './licence/licencecreate-modal/licencecreate-modal.component';
import { LicenceeditModalComponent } from './licence/licenceedit-modal/licenceedit-modal.component';
import { LicenceResolver } from './_resolvers/licence.resolver';
import { LicenceReportComponent } from './licence/licenceReport/licenceReport.component';
import { LicenceReportResolver } from './_resolvers/licenceReport.resolver';
import { PurchaseDepartmentComponent } from './purchaseDepartment/purchaseDepartment.component';
import { PurchaseDepartmentResolver } from './_resolvers/purchaseDepartment.resolver';
import { PurchaseDepartmentCreateModalComponent } from './purchaseDepartment/purchaseDepartmentCreate-Modal/purchaseDepartmentCreate-Modal.component';
import { PurchaseDepartmentEditModalComponent } from './purchaseDepartment/purchaseDepartmentEdit-Modal/purchaseDepartmentEdit-Modal.component';
import { PurchaseDepartmentDetailModalComponent } from './purchaseDepartment/purchaseDepartmentDetail-Modal/purchaseDepartmentDetail-Modal.component';
import { InvoiceMonthlyReportModalComponent } from './invoice/invoiceMonthlyReport-modal/invoiceMonthlyReport-modal.component';
import { CompanyPickerComponent } from './core/pickers/company-picker/company-picker.component';
import {Select3Module} from './core/pickers/select3/select3.module';
import {ReportsComponent} from "./reports/reports.component";


// tslint:disable-next-line: typedef
export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
      NavComponent,
      HomeComponent,
      HomeComponent,
      AdminPanelComponent,
      HasRoleDirective,
      UserManagementComponent,
      RolesModalComponent,
      CompanycreateModalComponent,
      CompanyComponent,
      CompanydetailModalComponent,
      CompanyeditModalComponent,
      TicketsComponent,
      MyTicketsComponent,
      TicketaddModalComponent,
      TicketMessagesComponent,
      TicketSolverEditModalComponent,
      PriorityComponent,
      PriorityCreateModalComponent,
      PriorityEditModalComponent,
      CategoryComponent,
      CategoryEditModalComponent,
      CategoryCreateModalComponent,
      WorkReportsComponent,
      WorkReportCreateModalComponent,
      WorkReportEditModalComponent,
      WorkReportDetailModalComponent,
      AdminHomeComponent,
      BasePriceListComponent,
      BasePriceListItemCreateModalComponent,
      BasePriceListItemEditModalComponent,
      PriceListComponent,
      PriceListItemCreateModalComponent,
      PriceListItemEditModalComponent,
      OrdersComponent,
      InvoiceComponent,
      InvoiceEditModalComponent,
      InvoiceCreateModalComponent,
      InvoiceDetailModalComponent,
      InvoiceWpsModalComponent,
      UserEditComponent,
      RolegroupsComponent,
      AddGroupComponent,
      EditGroupComponent,
      InventoryItemsComponent,
      InventoryItemCreateModalComponent,
      InventoryItemEditModalComponent,
      InventoryItemDetailModalComponent,
      DomainComponent,
      DomainCreateModalComponent,
      DomainEditModalComponent,
      DomainDetailModalComponent,
      TicketsForRepeatComponent,
      TicketEditModalComponent,
      RegisterUserModalComponent,
      PsswdChangeModalComponent,
      UserDataEditModalComponent,
      UserDataDetailModalComponent,
      UploadComponentComponent,
      NotificationsSetModalComponent,
      TicketDataEditModalComponent,
      WorkPostComponent,
      WorkPostCreateModalComponent,
      WorkPostEditModalComponent,
      TicketDetailModalComponent,
      ItemOrdersComponent,
      ItemOrderDetailModalComponent,
      ItemOrderCreateModalComponent,
      ItemOrderEditModalComponent,
      IssuesComponent,
      IssueCreateModalComponent,
      IssueEditModalComponent,
      IssueDetailModalComponent,
      ReminderComponent,
      ReminderComponent,
      ReminderCreateModalComponent,
      ReminderEditModalComponent,
      SendReminderModalComponent,
      IssuesOrderOverviewComponent,
      IssuesDeliveryListOverviewComponent,
      IssueInvoiceOverviewComponent,
      ItemOrderOverviewComponent,
      ItemOrderReceiptOverviewComponent,
      ItemOrderInvoiceOverviewComponent,
      LicenceComponent,
      LicencecreateModalComponent,
      LicenceeditModalComponent,
      LicenceReportComponent,
      PurchaseDepartmentComponent,
      PurchaseDepartmentCreateModalComponent,
      PurchaseDepartmentEditModalComponent,
      PurchaseDepartmentDetailModalComponent,
      InvoiceMonthlyReportModalComponent,
      CompanyPickerComponent,
    ReportsComponent
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxQRCodeModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule,
    TabsModule.forRoot(),
    TimeagoModule.forRoot(),
    RouterModule.forRoot(approutes),
    NgxGalleryModule,
    FileUploadModule,
    NgxChartsModule,
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    TimepickerModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost:5001'],
        disallowedRoutes: ['localhost:5000/api/auth']
      }
    }),
    Select3Module
  ],
  providers: [
    AuthService,
    ErrorInterceptorProvider,
    MemberDetailResolver,
    MemberListResolver,
    MemberEditResolver,
    ListsResolver,
    MessagesResolver,
    AdminService,
    CompaniesResolver,
    TicketsResolver,
    TicketResolver,
    UserWithRolesResolver,
    PrioritiesResolver,
    DatePipe,
    CategoriesResolver,
    WorkReportResolver,
    AdminHomeResolver,
    BasePriceListResolver,
    PriceListItemsResolver,
    OrdersResolver,
    InvoicesResolver,
    userEditResolver,
    GroupsResolver,
    InventoryItemsResolver,
    DomainResolver,
    WorkPostResolver,
    ItemOrdersResolver,
    RemindersResolver,
    LicenceResolver,
    LicenceReportResolver,
    PurchaseDepartmentResolver

  ],
  entryComponents: [
    RolesModalComponent,
    CompanycreateModalComponent,
    CompanydetailModalComponent,
    CompanyeditModalComponent,
    TicketaddModalComponent,
    TicketSolverEditModalComponent,
    PriorityCreateModalComponent,
    PriorityEditModalComponent,
    CategoryEditModalComponent,
    CategoryCreateModalComponent,
    WorkReportCreateModalComponent,
    WorkReportEditModalComponent,
    WorkReportDetailModalComponent,
    BasePriceListItemCreateModalComponent,
    BasePriceListItemEditModalComponent,
    PriceListItemCreateModalComponent,
    PriceListItemEditModalComponent,
    InvoiceEditModalComponent,
    InvoiceCreateModalComponent,
    InvoiceDetailModalComponent,,
    InvoiceWpsModalComponent,
    InvoiceMonthlyReportModalComponent,
    RolegroupsComponent,
    UserEditComponent,
    AddGroupComponent,
    EditGroupComponent,
    InventoryItemCreateModalComponent,
    InventoryItemEditModalComponent,
    InventoryItemDetailModalComponent,
    DomainComponent,
    DomainCreateModalComponent,
    DomainEditModalComponent,
    DomainDetailModalComponent,
    RegisterUserModalComponent,
    PsswdChangeModalComponent,
    UserDataEditModalComponent,
    UserDataDetailModalComponent,
    NotificationsSetModalComponent,
    TicketEditModalComponent,
    TicketDataEditModalComponent,
    WorkPostComponent,
    WorkPostCreateModalComponent,
    WorkPostEditModalComponent,
    TicketDetailModalComponent,
    ItemOrdersComponent,
    ItemOrderDetailModalComponent,
    ItemOrderCreateModalComponent,
    ItemOrderEditModalComponent,
    IssuesComponent,
    IssueCreateModalComponent,
    IssueEditModalComponent,
    IssueDetailModalComponent,
    ReminderComponent,
    ReminderCreateModalComponent,
    ReminderEditModalComponent,
    SendReminderModalComponent,
    IssuesOrderOverviewComponent,
    IssuesDeliveryListOverviewComponent,
    IssueInvoiceOverviewComponent,
    ItemOrderOverviewComponent,
    ItemOrderReceiptOverviewComponent,
    ItemOrderInvoiceOverviewComponent,
    LicenceComponent,
    LicencecreateModalComponent,
    LicenceeditModalComponent,
    LicenceReportComponent,
    PurchaseDepartmentComponent,
    PurchaseDepartmentCreateModalComponent,
    PurchaseDepartmentEditModalComponent,
    PurchaseDepartmentDetailModalComponent

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
