import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Company} from '../_models/company';
import {Licence} from '../_models/licence';
import {LicenceToOrder} from '../_models/licenceToOrder';
import {PaginatedResult, Pagination} from '../_models/pagination';
import {PriceListItem} from '../_models/priceListItem';
import {AlertifyService} from '../_services/alertify.service';
import {AuthService} from '../_services/auth.service';
import {CompanyDialService} from '../_services/companyDial.service';
import {LicenceService} from '../_services/licence.service';
import {PriceListService} from '../_services/pricelist.service';
import {PriceListItemCreateModalComponent} from './priceListItemCreate-modal/priceListItemCreate-modal.component';
import {PriceListItemEditModalComponent} from './priceListItemEdit-modal/priceListItemEdit-modal.component';

@Component({
  selector: 'app-priority',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.scss']
})
export class PriceListComponent implements OnInit {
  bsModalRef: BsModalRef;
  priceList: PriceListItem[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};
  licence: Licence[];
  AlreadyImported: boolean = true;
  @Input() defaultPriceList: boolean;

  constructor(
    private priceListService: PriceListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private licenceService: LicenceService
  ) {
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.pagination = data['pricelistitems'].pagination;
      // this.priceList = data['pricelistitems'].result;
    });


    this.companyService.getCompanies(1, 1, null, null, false, true).subscribe((data) => {
      this.companies = data.result;
      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['filterParam'];
        if (this.filterParams.companyId === '0' || this.filterParams.companyId === undefined) {
          this.filterParams.companyId = this.companies[0].id;
        }
        if (this.authService.decodedToken.IsPrimary !== 'True') {
          this.filterParams.companyId = parseFloat(this.authService.decodedToken.CompanyId);
        }

        this.loadPriceList();
      });
    });
  }

  deletePriceListItem(id: number) {
    this.alertify.confirm('Opravdu chcete vymazat tuto položku?', () => {
      this.priceListService.deletePriceListItem(id)
        .subscribe(() => {
          this.loadPriceList();
          this.alertify.success('Úspěšně odstraněno');
        }, error => {
          this.alertify.error('Položku se nepodařilo odstranit');
        });
    });
  }

  loadPriceList() {
    this.priceListService.getPriceListItems(this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
      .subscribe((res: PaginatedResult<PriceListItem[]>) => {
        this.priceList = res.result;
        this.pagination.totalPages = res.pagination.totalPages;
        this.pagination.itemsPerPage = res.pagination.itemsPerPage;
        this.pagination.totalItems = res.pagination.totalItems;
        this.AlreadyImported = this.priceList.filter(x => x.isImported == true).length > 0;
      }, error => {
        this.alertify.error(error);
      });
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadPriceList();
  }

  createModal() {
    const initialState: any = {
      companyId: this.filterParams.companyId,
      companies: this.companies
    };
    this.bsModalRef = this.modalService.show(PriceListItemCreateModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.createNewPriceListItem.subscribe((values) => {
      this.priceListService.addPriceListItem(values['values']).subscribe(res => {
        values['licence'].forEach(l => {
          if (l.amount > 0) {
            const lto: LicenceToOrder = {
              deleted: false,
              licenceId: l.id,
              orderId: res,
              lastPayment: new Date(),
              id: 0,
              amount: l.amount
            };
            this.licenceService.addLTO(lto).subscribe(data => {
            });
          }
        });
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Položku se nepodařilo vytvořit');
      }, () => {
        this.loadPriceList();
      });
    });
  }

  getEditModal(priceListItem: PriceListItem) {
    const initialState: any = {
      priceListItem,
      companyId: this.filterParams.companyId,
    };
    this.bsModalRef = this.modalService.show(PriceListItemEditModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.editPriceListItemEvent.subscribe((values) => {

      this.priceListService.updatePriceListItem(priceListItem.id, values['values']).subscribe(res => {
        //TODO add licence
        values['licence'].forEach(l => {
          if (l.amount > 0) {
            const lto: LicenceToOrder = {
              deleted: false,
              licenceId: l.id,
              orderId: res,
              lastPayment: new Date(),
              id: 0,
              amount: l.amount
            };
            this.licenceService.addLTO(lto).subscribe(data => {
            });
          }
        });


        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Položku se nepodařilo upravit');
      }, () => {
        this.loadPriceList();
      });
    });
  }

  resetFilters() {
    this.loadPriceList();
  }

  importPriceList() {
    this.priceListService.importDefaultPriceList(this.filterParams.companyId).subscribe(x => {
      this.alertify.success('Úspěšně importováno');
      this.loadPriceList();
    }, error => {
      this.alertify.error('Import neúspěšný');
    });
  }

  setCompanyToFilter($event: number) {
    this.filterParams.companyId = $event;
    this.loadPriceList();
  }
}
