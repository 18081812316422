import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {PaginatedResult} from '../_models/pagination';
import {map} from 'rxjs/operators';
import {PriceListItem} from '../_models/priceListItem';

@Injectable({
  providedIn: 'root'
})
export class PriceListService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }


  addPriceListItem(pricelistitem: PriceListItem) {
    return this.http.post<number>(this.baseUrl + 'PriceListItem/', pricelistitem);
  }

  deletePriceListItem(pricelistitemId: number) {
    return this.http.delete(this.baseUrl + 'PriceListItem/' + pricelistitemId);
  }

  getPriceListItems(page?, itemsPerPage?, userParams?) {
    const paginatedResult: PaginatedResult<PriceListItem[]> = new PaginatedResult<PriceListItem[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
      if (userParams.companyId) {
        params = params.append('CompanyId', userParams.companyId);
      }
    }
    return this.http.get<PriceListItem[]>(this.baseUrl + 'PriceListItem', {observe: 'response', params})
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') !== null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }

  getPriceListItemsForOrderList(userParams?) {
    let params = new HttpParams();
    if (userParams != null) {
      params = params.append('Month', userParams.month);
      params = params.append('CompanyId', userParams.companyId);
      params = params.append('FilterInvoiced', userParams.filterInvoiced);
      params = params.append('DeductHours', userParams.deductHours);
      params = params.append('GetHours', userParams.getHours);
    }
    return this.http.get<PriceListItem[]>(this.baseUrl + 'PriceListItem/GetPriceListItemsForOrderList', {
      observe: 'response',
      params
    });
  }

  getPriceListItemsByInvoiceId(invoiceId) {
    return this.http.get<PriceListItem[]>(this.baseUrl + 'PriceListItem/GetPriceListItemsByInvoiceId/' + invoiceId);
  }

  updatePriceListItem(id: number, pricelistitem: PriceListItem) {
    return this.http.put<number>(this.baseUrl + 'PriceListItem/' + id, pricelistitem);
  }


  importDefaultPriceList(companyid: number) {
    return this.http.post(this.baseUrl + 'PriceListItem/ImportDefaultPriceList/' + companyid, {});
  }
}
