import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BasePriceListItem } from '../_models/basePriceListItem';
import { BasePriceListService } from '../_services/basepricelist.service';
@Injectable()
export class BasePriceListResolver implements Resolve<BasePriceListItem[]> {
  pageNumber = 1;
  pageSize = 15;

  constructor(
    private basePriceListService: BasePriceListService,
    private router: Router,
    private alertify: AlertifyService,
  ) {}

  resolve(): Observable<BasePriceListItem[]> {
    return this.basePriceListService.getBasePriceListItems(this.pageNumber, this.pageSize).pipe(
      catchError(() => {
        this.alertify.error('Problem retrieving price list');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
