import {NgModule} from '@angular/core';
import {DatePipe} from '@angular/common';
import {CommonPipesModule} from './_common/common-pipes.module';

@NgModule({
  imports: [
    CommonPipesModule
  ],
  declarations: [

  ],
  exports: [
    CommonPipesModule
  ],
  providers: [
    DatePipe
  ]
})

export class PipesModule {
}

