import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Company} from '../_models/company';
import {PaginatedResult, Pagination} from '../_models/pagination';
import {AlertifyService} from '../_services/alertify.service';
import {AuthService} from '../_services/auth.service';
import {CompanyDialService} from '../_services/companyDial.service';
import {PriceListService} from '../_services/pricelist.service';
import {MonthlyReportService} from '../_services/monthlyReport.service';
import {DomainsReportEntity, MonthlyReport} from '../_models/monthlyReport';
import {DatePipe} from "@angular/common";
import {MonthlyReportEntity} from "../_models/monthlyReportEntity";
import {Mailbox} from "../_models/mailbox";
import {SqlItem} from "../_models/sqlItem";
import {FileReportItem} from "../_models/fileReportItem";

@Component({
  selector: 'app-reports-component',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit {
  bsModalRef: BsModalRef;
  // priceList: PriceListItem[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};
  // licence: Licence[];

  monthlyReport: MonthlyReport;
  monthlyReportEntities: MonthlyReportEntity[];
  monthlyServerReportEntities: MonthlyReportEntity[] = [];
  monthlyPCReportEntities: MonthlyReportEntity[] = [];
  PCCount = 0;
  ServerCount = 0;
  mailboxes: DomainsReportEntity[];
  sqlItems: DomainsReportEntity[];
  fileReportItems: DomainsReportEntity[];

  constructor(
    private priceListService: PriceListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private companyService: CompanyDialService,
    private reportService: MonthlyReportService,
    private datePipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.resetFilters();
  }

  loadReport() {
    if (this.filterParams.companyId === 0) {
      return;
    }

    const date = new Date(this.filterParams.created);

    this.reportService.getBasicReport(this.filterParams.companyId, date.getMonth() + 1, date.getFullYear())
      .subscribe((data: MonthlyReport) => {

        console.log(data)

        this.monthlyReport = data;
        this.monthlyReport.month = data.pcReport.reportedEntities[0]?.month;
        this.monthlyReport.year = data.pcReport.reportedEntities[0]?.year;
        this.monthlyReportEntities = data.pcReport.reportedEntities;
        this.mailboxes = data.mailboxes;
        this.sqlItems = data.sqlItems;
        this.fileReportItems = data.fileReportItems;

        this.monthlyServerReportEntities = [];
        this.monthlyPCReportEntities = [];
        this.ServerCount = 0;
        this.PCCount = 0;

        data.pcReport.reportedEntities.forEach(x => {
          const isServer = x.name.startsWith('S', 0);

          if (isServer) {
            this.monthlyServerReportEntities.push(x);
            this.ServerCount++;
          } else {
            this.monthlyPCReportEntities.push(x);
            this.PCCount++;
          }
        });

      }, error => {
        this.alertify.error(error);
      });
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadReport();
  }

  resetFilters() {
    this.filterParams = {};
    this.filterParams.companyId = 0;
    this.filterParams.created = this.datePipe.transform(Date.now(), 'yyyy-MM');
    this.loadReport();
  }

  setCompanyToFilter($event: number) {
    this.filterParams.companyId = $event;
    this.loadReport();
  }
}
