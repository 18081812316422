import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PriceListItem } from '../_models/priceListItem';
import { PriceListService } from '../_services/pricelist.service';

@Injectable()
export class PriceListItemsResolver implements Resolve<PriceListItem[]> {
  pageNumber = 1;
  pageSize = 15;

  constructor(
    private priceListService: PriceListService,
    private router: Router,
    private alertify: AlertifyService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PriceListItem[]> {
    var filterParams: any= {};
    filterParams.companyId = route.params['filterParam'];
    return this.priceListService.getPriceListItems(this.pageNumber, this.pageSize,filterParams).pipe(
      catchError(() => {
        this.alertify.error('Problem retrieving price list');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
