<div class="m-5">
  <h2>Základní reporty</h2>
  <form class="form-inline" #form="ngForm" (ngSubmit)="loadReport()" novalidate>
    <input class="ml-1" type="month" [(ngModel)]="filterParams.created" name="month" (change)="loadReport()">
    <div class="form-group px-2">
      <label for="companyId">Firma:&nbsp;</label>
      <app-company-picker style="width: 250px"
                          id="companyId"
                          name="companyId"
                          [(ngModel)]="filterParams.companyId"
                          #companyId="ngModel"
                          (change)="setCompanyToFilter($event)" required></app-company-picker>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()">Reset Filtrů</button>
  </form>
  <!--</div>-->
  <!--  <h2 class="m-0 p-0" *ngIf="filterParams.companyId ==='1'">Základní ceník</h2>-->
  <!--  <div *ngIf="priceList !== undefined" class="m-3 ">-->
  <!--    <div *ngIf=" priceList.length === 0" style="text-align: center">-->
  <!--      <h1>Report pro daný filtr nebyl nalezen</h1>-->
  <!--    </div>-->

  <div class="row w-100" *ngIf="monthlyReport != null">
    <tabset #ticketTabs class="member-tabset w-75">
      <tab heading="Servery">
        <div class="container">
          <table class="table table-hover mb-0" style="cursor: pointer;">
            <thead>
            <tr>
              <td style="width: 30%"><strong>{{monthlyReport.companyName}}</strong></td>
              <td style="width: 30%"></td>
              <td style="width: 30%"></td>
              <td style="width: 10%"><strong>Celkem: {{ServerCount}}</strong></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width: 30%">Doména</td>
              <td style="width: 30%">Server</td>
              <td style="width: 30%">OS</td>
              <td style="width: 10%">Počet</td>
            </tr>
            <tr *ngFor="let entity of monthlyServerReportEntities">
              <td style="width: 30%">{{entity?.domain}}</td>
              <td style="width: 30%">{{entity?.name}}</td>
              <td style="width: 30%">{{entity?.operatingSystem}}</td>
              <td style="width: 10%">{{entity?.count}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </tab>
      <tab heading="PC">
        <div class="container">
          <table class="table table-hover mb-0" style="cursor: pointer;">
            <thead>
            <tr>
              <td style="width: 30%"><strong>{{monthlyReport.companyName}}</strong></td>
              <td style="width: 30%"></td>
              <td style="width: 30%"></td>
              <td style="width: 10%"><strong>Celkem: {{PCCount}}</strong></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width: 30%">Doména</td>
              <td style="width: 30%">PC</td>
              <td style="width: 30%">OS</td>
              <td style="width: 10%">Počet</td>
            </tr>
            <tr *ngFor="let entity of monthlyPCReportEntities">
              <td style="width: 30%">{{entity?.domain}}</td>
              <td style="width: 30%">{{entity?.name}}</td>
              <td style="width: 30%">{{entity?.operatingSystem}}</td>
              <td style="width: 10%">{{entity?.count}}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </tab>
      <tab heading="E-mailové schránky">
        <div class="container">
          <ng-container *ngFor="let domain of mailboxes">
            {{domain.domain}}
            <table class="table table-hover mb-0" style="cursor: pointer;">
              <thead>
              <tr>
                <td style="width: 30%"><strong>Doména</strong></td>
                <td style="width: 50%"><strong>PrimarySMTP Adress</strong></td>
                <td style="width: 20%"><strong>Velikost schránky (MB)</strong></td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let mailbox of domain.mailboxes">
                <td style="width: 30%">{{mailbox.domain}}</td>
                <td style="width: 50%">{{mailbox.primarySmtpAddress}}</td>
                <td style="width: 20%">{{mailbox.mailboxSizeMB}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </tab>
      <tab heading="SQL databáze">
        <div class="container">
          <ng-container *ngFor="let domain of sqlItems">
            {{domain.domain}}
          <table class="table table-hover mb-0" style="cursor: pointer;">
            <thead>
            <tr>
              <td style="width: 30%"><strong>Instance</strong></td>
              <td style="width: 50%"><strong>Jméno databáze</strong></td>
              <td style="width: 20%"><strong>Velikost dat (MB)</strong></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sqlItem of domain.sqlInstances">
              <td style="width: 30%">{{sqlItem.sqlInstance}}</td>
              <td style="width: 50%">{{sqlItem.databaseName}}</td>
              <td style="width: 20%">{{sqlItem.sizeMB}}</td>
            </tr>
            </tbody>
          </table>
          </ng-container>
        </div>
      </tab>
      <tab heading="Report souborů">
        <div class="container">
          <ng-container *ngFor="let domain of fileReportItems">
            {{domain.domain}}
            <table class="table table-hover mb-0" style="cursor: pointer;">
              <thead>
              <tr>
                <td style="width: 30%"><strong>Instance</strong></td>
                <td style="width: 50%"><strong>Jméno</strong></td>
                <td style="width: 20%"><strong>Velikost dat (MB)</strong></td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let fileItem of domain.fileReportItems">
                <td style="width: 30%">{{fileItem.instance}}</td>
                <td style="width: 50%">{{fileItem.folderName}}</td>
                <td style="width: 20%">{{fileItem.sizeMB}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </tab>
    </tabset>
  </div>
  <!--    <table class="table table-hover" style="cursor: pointer">-->
  <!--      <tr>-->
  <!--        <th style="width: 7%">Kód služby</th>-->
  <!--        <th style="width: 15%">Název</th>-->
  <!--        <th style="width: 15%">Firma</th>-->
  <!--        <th style="width: 5%">Cena za mj</th>-->
  <!--        <th style="width: 5%">Cena za mj (s DPH)</th>-->
  <!--        <th style="width: 5%">Měsíčně</th>-->
  <!--        <th style="width: 5%">Import</th>-->
  <!--        <th style="width: 15%">Jednotka slovně</th>-->
  <!--        <th style="width: 20%">Definice služby</th>-->

  <!--        <th style="width: 8%"></th>-->
  <!--      </tr>-->
  <!--      <tr *ngFor="let priceListItem of priceList" >-->
  <!--        <td>{{priceListItem.code}}</td>-->
  <!--        <td>{{priceListItem.name}}</td>-->
  <!--        <td>{{priceListItem.companyName}}</td>-->
  <!--        <td>{{priceListItem.price}}</td>-->
  <!--        <td>{{priceListItem.price*1.21}}</td>-->
  <!--        <td *ngIf="priceListItem.isMonthly"><i class="fa fa-check-circle-o" style="color:grey"></i></td>-->
  <!--        <td *ngIf="!priceListItem.isMonthly"></td>-->
  <!--        <td *ngIf="priceListItem.isImported"><i class="fa fa-check-circle-o" style="color:grey"></i></td>-->
  <!--        <td *ngIf="!priceListItem.isImported"></td>-->
  <!--        <td>{{priceListItem.unitDefinition}}</td>-->
  <!--        <td>-->
  <!--          <div  class="form-group">-->
  <!--            <textarea readonly  type="text"  class="form-control " [value]= priceListItem.serviceDescription-->
  <!--             style="min-height: 40px;" ></textarea>-->
  <!--          </div>-->

  <!--        </td>-->

  <!--        <td>-->
  <!--          <div  class="form-inline">-->
  <!--            <div>-->
  <!--              <button class="btn btn-primary mr-2" (click)="$event.stopPropagation()" (click)="getEditModal(priceListItem)" ><i class="fa fa-edit ml-1"></i></button>-->
  <!--              <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deletePriceListItem(priceListItem.id)" ><i class="fa fa-trash"></i></button>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </td>-->
  <!--      </tr>-->
  <!--    </table>-->
  <!--  </div>-->
  <!--</div>-->
  <!--<div class="d-flex justify-content-center">-->
  <!--    <pagination [maxSize]="3"-->
  <!--                [boundaryLinks]="true"-->
  <!--                [totalItems]="pagination.totalItems"-->
  <!--                [itemsPerPage]="pagination.itemsPerPage"-->
  <!--                [(ngModel)]="pagination.currentPage"-->
  <!--                (pageChanged)="pageChanged($event)"-->
  <!--              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">-->
  <!--  </pagination>-->
  <!--  </div>-->
  <!--</div>-->
  <div *ngIf="filterParams.companyId == 0 || monthlyReport == null" class="m-5">
    <div class="container ">
      <div style="text-align: center">
        <h1>Pro daný filtr jsme nenalezli žádná data</h1>
      </div>
    </div>
  </div>
</div>
