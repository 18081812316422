<form #f="ngForm" (ngSubmit)="f.form.valid && createPriceListItem()">
  <div class="modal-header">
    <h4>Vytvoření nové položky ceníku</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="name">Název</label>
      <input type="text" class="form-control" name="name" id="name" placeholder="Název"
             [(ngModel)]="priceListItem.name" #name="ngModel" required/>
      <div *ngIf="f.submitted && name.errors?.required" class="text-danger">Název je povinné pole</div>
    </div>
    <div class="form-group">
      <label for="price">Cena</label>
      <input type="number" class="form-control" name="price" id="price" placeholder="Cena" [min]="minPrice"
             [(ngModel)]="priceListItem.price" #price="ngModel" required/>
      <div *ngIf="f.submitted && price.errors?.required" class="text-danger">Cena je povinné pole</div>
    </div>
    <div class="form-group form-inline ml-1">
      <input type="checkbox" class="form-control" name="unitIsVariable" id="unitIsVariable"
             placeholder="Jednotky jsou proměnné z reportu"
             [(ngModel)]="priceListItem.unitIsVariable" #unitIsVariable="ngModel" (change)="changeUnitIsVariable($event)"/>
      <label for="unitIsVariable" class="ml-2">Jednotky jsou proměnné z reportu</label>
    </div>

    <ng-container *ngIf="priceListItem.unitIsVariable">
      <select class="form-control" [(ngModel)]="priceListItem.variableType" #variableType="ngModel" id="variableType" name="variableType">
        <option [value]="1">Exchange report</option>
        <option [value]="2">SQL report</option>
        <option [value]="3">Report souborů</option>
        <option [value]="4">PCCode</option>
        <option [value]="5">PCProCode</option>
        <option [value]="6">ServerCode</option>
        <option [value]="7">KmCode</option>
        <option [value]="8">ONSCode</option>
        <option [value]="9">RASCode</option>
      </select>
    </ng-container>

    <ng-container *ngIf="!priceListItem.unitIsVariable">
      <div class="form-group">
        <label for="unitCount">Počet jednotek</label>
        <input type="number" class="form-control" name="unitCount" id="unitCount" placeholder="Počet jednotek" [min]="1"
               [(ngModel)]="priceListItem.unitCount" #unitCount="ngModel" required/>
        <div *ngIf="f.submitted && unitCount.errors?.required" class="text-danger">Počet jednotek je povinné pole</div>
      </div>
    </ng-container>

    <div class="form-group form-inline ml-1">
      <input type="checkbox" class="form-control" name="isMonthly" id="isMonthly" placeholder="Měsíční odběr"
             [(ngModel)]="priceListItem.isMonthly" #isMonthly="ngModel"/>
      <label for="isMonthly" class="ml-2">Měsíční odběr</label>
    </div>

    <div class="form-group">
      <label for="code">Kód</label>
      <input type="text" class="form-control" name="code" id="code" placeholder="Kód"
             [(ngModel)]="priceListItem.code" #codePriceList="ngModel" required/>
      <div *ngIf="f.submitted && codePriceList.errors?.required" class="text-danger">Kód je povinné pole</div>
    </div>

    <div class="form-group">
      <label for="unitDefinition">Jednotka slovně</label>
      <input type="text" class="form-control" name="unitDefinition" id="unitDefinition" placeholder="Jednotka slovně"
             [(ngModel)]="priceListItem.unitDefinition" #unitDefinition="ngModel"/>
    </div>

    <div class="form-group">
      <label for="serviceDescription">Popis služby</label>
      <textarea type="text" class="form-control" name="serviceDescription" id="serviceDescription"
                placeholder="Popis služby"
                [(ngModel)]="priceListItem.serviceDescription" #serviceDescription="ngModel"></textarea>
    </div>

    <table class="table table-hover" style="cursor: pointer">
      <tr>
        <!-- <th style="width: 15%"></th> -->
        <th style="width: 40%">Licence</th>
        <th style="width: 40%">Množství</th>
        <th style="width: 20%"></th>
      </tr>
      <tr *ngFor="let item of setLicences; index as i">
        <td>{{item.name}}</td>
        <td>{{item.amount}}</td>
        <td>
          <button class="btn btn-danger" (click)="deleteLicence(i)"><i class="fa fa-trash"></i></button>
        </td>
      </tr>

      <tr *ngIf="selectedLicence != null">
        <!-- <th style="width: 15%"></th> -->
        <td>
          <select class="form-control" [(ngModel)]="selectedLicenceId" (change)="changeSelectedLicenceId($event)"
                  #licencesSelect="ngModel" id="licencesSelect" name="licencesSelect">
            <option *ngFor="let item of filteredLicences" [value]="item.id">{{item.name}}</option>
          </select>
        </td>
        <td colspan="2">
          <input min="0" type="number" class="form-control" placeholder="Množství"
                 [(ngModel)]="selectedLicence.amount" name="amount" id="amount" #amount="ngModel"/>
        </td>

      </tr>
      <tr>
        <td>
          <button *ngIf="selectedLicence != null" type="button" class="btn btn-default"
                  (click)="addLicence(selectedLicence)"><i class="fa fa-plus"></i>&nbsp;Přidat licenci
          </button>
        </td>
        <td colspan="2" class="pt-3">
          <span>Cena za licence:&nbsp;{{priceListItem.priceInLicences}}</span>
        </td>
      </tr>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
    <button type="submit" class="btn btn-success">Uložit</button>
  </div>
</form>
