<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="ml-3 mr-3 mt-2 ">

  <form [formGroup]="itemOrderAddForm" (ngSubmit)="createOrder()">
    <div class="form-group">
      <select  formControlName="customerId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect"
      [ngClass]="{ 'is-invalid': itemOrderAddForm.get('customerId').errors && itemOrderAddForm.get('customerId').touched}">
      <option  value="">Vyberte firmu</option>
      <!-- <option *ngIf="" value="">Vyberte dodavatele</option> -->
      <option *ngFor="let company of companiesClient" [value] = "company.id">
         {{company.companyName}}
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Datum objednávky : </h4>
      <input type="date" [ngClass]="{'is-invalid': itemOrderAddForm.get('dateOfOrder').errors && itemOrderAddForm.get('dateOfOrder').touched }" class="form-control is-invalid"
      formControlName="dateOfOrder" />
    </div>
  </form>


    <div class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <strong style="width: 50%;">Název</strong>
          <strong style="width: 25%;">Obj. mn</strong>
          <strong style="width: 25%;">Cena</strong>
        </div>
      </div>
      </div>

    <div *ngFor="let orderedItem of orderedItems" >
      <div class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <input (change)="checkInvItems()" [ngClass]="{'border-danger' : orderedItem.name === undefined || orderedItem.name === ''}"  style="width: 50%;" class="form-control" placeholder="Název"  [(ngModel)]="orderedItem.name" name="name"/>
          <div class="invalid-feedback" *ngIf="">Název je povinné pole</div>
          <input (change)="checkInvItems()" [ngClass]="{'border-danger' : orderedItem.amount === 0 || orderedItem.amount === null}" style="width: 25%;" type="number" min="0" class="form-control" placeholder="obj. množství"  [(ngModel)]="orderedItem.amount" />
          <input (change)="checkInvItems()" [ngClass]="{'border-danger' : orderedItem.price === undefined || orderedItem.price === null}" style="width: 25%;" type="number" min="0" class="form-control" placeholder="Cena"  [(ngModel)]="orderedItem.price" />
        </div>

      </div>
      <a></a>
      <button  type="button" class="btn btn-primary " style="width: 10%; " (click)="removeInvoiceItem(orderedItem.dynamicId)"><strong>X</strong></button>
      </div>
  </div>

  <button type="button" class="btn btn-primary w-100" (click)="addEmptyInvoiceItem()">Přidat položku</button>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!(itemOrderAddForm.valid  && itemsValid)" type="button" class="btn btn-success" form="itemOrderAddForm" (click)="createOrder()">Uložit</button>
  <!-- && orderedItems != undefined && orderedItems.length > 0 -->
</div>
