<div *ngIf="companies != undefined && companies.length > 0" class="m-5">
  <div *ngIf="filterParams.companyId !== '1'">
  <h2 >Ceník</h2>

  <form *appHasRole="['CompanyFilter']" class="form-inline" #form ="ngForm" (ngSubmit)="loadPriceList()" novalidate>
    <div class="form-group px-2">
      <label for="companyId">Firma:&nbsp;</label>
      <app-company-picker style="width: 250px"
                          id="companyId"
                          name="companyId"
                          [(ngModel)]="filterParams.companyId"
                          #companyId="ngModel"
                          (change)="setCompanyToFilter($event)" required></app-company-picker>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <!-- <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button> -->
  </form>
</div>
  <h2 class="m-0 p-0" *ngIf="filterParams.companyId ==='1'">Základní ceník</h2>

  <button class="btn btn-primary " (click)="createModal()" >Přidat</button>
  <button  class="btn btn-primary float-right  mt-5" *ngIf="filterParams.companyId !=='1' && !AlreadyImported" (click)="importPriceList()" >Importovat základní ceník</button>
  <button  class="btn btn-primary float-right  mt-5" *ngIf="filterParams.companyId !=='1' && AlreadyImported" (click)="importPriceList()" >Reimportovat základní ceník</button>
  <div *ngIf="priceList !== undefined" class="m-3 ">
    <div *ngIf=" priceList.length === 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné položky</h1>
    <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou položku</button>
    </div>

  <div class="row w-100" *ngIf="priceList != undefined && priceList.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <tr>
        <th style="width: 7%">Kód služby</th>
        <th style="width: 15%">Název</th>
        <th style="width: 15%">Firma</th>
        <th style="width: 5%">Cena za mj</th>
        <th style="width: 5%">Cena za mj (s DPH)</th>
        <th style="width: 5%">Měsíčně</th>
        <th style="width: 5%">Import</th>
        <th style="width: 15%">Jednotka slovně</th>
        <th style="width: 20%">Definice služby</th>

        <th style="width: 8%"></th>
      </tr>
      <tr *ngFor="let priceListItem of priceList" >
        <td>{{priceListItem.code}}</td>
        <td>{{priceListItem.name}}</td>
        <td>{{priceListItem.companyName}}</td>
        <td>{{priceListItem.price}}</td>
        <td>{{priceListItem.price*1.21}}</td>
        <td *ngIf="priceListItem.isMonthly"><i class="fa fa-check-circle-o" style="color:grey"></i></td>
        <td *ngIf="!priceListItem.isMonthly"></td>
        <td *ngIf="priceListItem.isImported"><i class="fa fa-check-circle-o" style="color:grey"></i></td>
        <td *ngIf="!priceListItem.isImported"></td>
        <td>{{priceListItem.unitDefinition}}</td>
        <td>
          <div  class="form-group">
            <textarea readonly  type="text"  class="form-control " [value]= priceListItem.serviceDescription
             style="min-height: 40px;" ></textarea>
          </div>

        </td>

        <td>
          <div  class="form-inline">
            <div>
              <button class="btn btn-primary mr-2" (click)="$event.stopPropagation()" (click)="getEditModal(priceListItem)" ><i class="fa fa-edit ml-1"></i></button>
              <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deletePriceListItem(priceListItem.id)" ><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true"
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>
</div>
<!-- <div *ngIf="companies == undefined || companies.length < 1" class="m-5">
  <div class="container ">
    <div style="text-align: center">
      <h1>Zatím nejsou přidané žádné firmy</h1>
    </div>
  </div>
</div> -->
