import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Company} from '../_models/company';
import {Licence} from '../_models/licence';
import {LicenceToOrder} from '../_models/licenceToOrder';
import {PaginatedResult, Pagination} from '../_models/pagination';
import {BasePriceListItem} from '../_models/basePriceListItem';
import {AlertifyService} from '../_services/alertify.service';
import {LicenceService} from '../_services/licence.service';
import {BasePriceListService} from '../_services/basepricelist.service';
import {
  BasePriceListItemCreateModalComponent
} from './basepriceListItemCreate-modal/basepriceListItemCreate-modal.component';
import {BasePriceListItemEditModalComponent} from './basepriceListItemEdit-modal/basepriceListItemEdit-modal.component';

@Component({
  selector: 'app-priority',
  templateUrl: './basepricelist.component.html',
  styleUrls: ['./basepricelist.component.scss']
})
export class BasePriceListComponent implements OnInit {
  bsModalRef: BsModalRef;
  basePriceList: BasePriceListItem[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};
  licence: Licence[];
  AlreadyImported: boolean = true;
  @Input() BasePriceList: boolean;

  constructor(
    private basePriceListService: BasePriceListService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private licenceService: LicenceService
  ) {
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.pagination = data['basepricelistitems'].pagination;
      this.basePriceList = data['basepricelistitems'].result;
    });
  }

  loadBasePriceList() {
    this.basePriceListService.getBasePriceListItems(this.pagination.currentPage, this.pagination.itemsPerPage)
      .subscribe((res: PaginatedResult<BasePriceListItem[]>) => {
        this.basePriceList = res.result;
        this.pagination.totalPages = res.pagination.totalPages;
        this.pagination.itemsPerPage = res.pagination.itemsPerPage;
        this.pagination.totalItems = res.pagination.totalItems;
      }, error => {
        this.alertify.error(error);
      });
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadBasePriceList();
  }

  createModal() {
    const initialState: any = {
      companyId: this.filterParams.companyId,
    };
    this.bsModalRef = this.modalService.show(BasePriceListItemCreateModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.createNewBasePriceListItem.subscribe((values) => {
      this.basePriceListService.addBasePriceListItem(values['values']).subscribe(res => {
        values['licence'].forEach(l => {
          if (l.amount > 0) {
            const lto: LicenceToOrder = {
              deleted: false,
              licenceId: l.id,
              orderId: res,
              lastPayment: new Date(),
              id: 0,
              amount: l.amount
            };
            this.licenceService.addLTO(lto).subscribe(data => {
            });
          }
        });
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Položku se nepodařilo vytvořit');
      }, () => {
        this.loadBasePriceList();
      });
    });
  }

  deletePriceList(basePriceListItem: BasePriceListItem) {
    this.alertify.confirm('Opravdu chcete vymazat tuto ceníkovou položku?', () => {
      this.basePriceListService.deleteBasePriceListItem(basePriceListItem.id)
        .subscribe(() => {
          this.loadBasePriceList();
          this.alertify.success('Úspěšně odstraněno');
        }, error => {
          this.alertify.error('Ceník se nepodařilo odstranit');
        });
    });
  }

  getEditModal(basePriceListItem: BasePriceListItem) {
    const initialState: any = {
      basePriceListItem,
      companyId: this.filterParams.companyId,
    };
    this.bsModalRef = this.modalService.show(BasePriceListItemEditModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.editBasePriceListItemEvent.subscribe((values) => {

      this.basePriceListService.updateBasePriceListItem(basePriceListItem.id, values['values']).subscribe(res => {
        //TODO add licence
        values['licence'].forEach(l => {
          if (l.amount > 0) {
            const lto: LicenceToOrder = {
              deleted: false,
              licenceId: l.id,
              orderId: res,
              lastPayment: new Date(),
              id: 0,
              amount: l.amount
            };
            this.licenceService.addLTO(lto).subscribe(data => {
            });
          }
        });


        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Položku se nepodařilo upravit');
      }, () => {
        this.loadBasePriceList();
      });
    });
  }
}
